<template>
    <b-modal id="email_varification" title="Your Email Address" hide-footer modal-class="customer-home-modal" no-stacking>
        <div class="modal-body">
            <ValidationObserver v-slot="{ passes }">
                <div class="header pb-3 text-center">
                    <p class="lead px-3">Finally, where do we send your receipts?</p>
                </div>
                <div class="body">
                    <p class="text-center mb-4">Provide an email you want us to save on your PayAngel account.</p>
                    <form class="form-auth-small" @submit.prevent="passes(emailVarificationFormSubmit)" id="registration" ref="registration">
                        <ValidationProvider name="email" rules="required|email" v-slot="{ errors}">
                            <div class="form-group" :class="{'errors': errors.length && !email || errors[0],'success':email && !errors[0]}">
                                <span class="float-label">
                                    <input type="email" v-model="email" class="form-control" name="email1" id="email" placeholder="Email*">
                                    <label for="email" class="control-label">Email*</label>
                                    <div class="line"></div>
                                    <i class="fa fa-times" v-if="errors.length && !email || errors[0] "></i>
                                    <i class="fa fa-check" v-if="email && !errors[0]"></i>
                                </span>
                            </div>
                        </ValidationProvider>
                        <b-button type="submit" block variant="gradiunt" size="lg">CONTINUE</b-button>
                        <div class="bottom text-center mt-2">
                            <router-link class="text-body text-decoration-underline" to="/admin/dashboard-v1">I will do this later</router-link>
                        </div>
                    </form>
                </div>
            </ValidationObserver>
        </div>
    </b-modal>
</template>
<script>
const { ValidationObserver,ValidationProvider } = require("vee-validate")
export default {
    name:'EmailVarificationModal',
    components: {
        ValidationObserver,
        ValidationProvider,
    },methods: {
        emailVarificationFormSubmit() {
            this.$router.push({ path: '/admin/dashboard-v1' })
        }
    },data(){
        return{
            email:'',
        }
    }
}
</script>